var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"item-properties":[
        {
          key: 'tenantName',
          header: 'label.club',
          sortable: true
        },
        {
          header: 'label.accept',
          icon: 'mdi-account-plus',
          iconClickHandler: membershipRequest => membershipRequest.accept()
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: membershipRequest => _vm.$dialog.openDialog('membership-request', membershipRequest)
        }
      ],"items":_vm.membershipRequestList.membershipRequests,"default-sort-property-key":"tenantId","item-key":"id","no-items-label":"message.noDataFound","search":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }