import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import $authorization from '@/lib/authorization'
import useLoader from '@/lib/composables/useLoader'
import useMembershipRequest from '@/app/composables/membership/useMembershipRequest'

export default function () {
  const membershipRequestList = Vue.observable({
    loader: useLoader(),
    membershipRequests: [],
    fetch: null
  })

  membershipRequestList.setData = data => {
    membershipRequestList.membershipRequests = data.map(membershipRequestData => {
      const membershipRequest = useMembershipRequest()
      membershipRequest.setData(membershipRequestData)
      return membershipRequest
    })
  }

  membershipRequestList.load = async () => {
    await membershipRequestList.loader.load(async background => {
      if (background) {
        await membershipRequestList.fetch.executeFetch()
      } else {
        membershipRequestList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/membership-requests`)
        await membershipRequestList.fetch.promise
      }
      membershipRequestList.setData(membershipRequestList.fetch.data)
    })
  }

  return membershipRequestList
}
