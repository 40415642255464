var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{ref:"transactionsTable",attrs:{"default-sort-ascending":false,"item-filtering-handler":_vm.transactionSearch.search,"item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'context',
          type: 'icon',
          icon: transaction => {
            switch (transaction.context) {
              case 'ORDER': return {
                image: 'mdi-shopping',
                tooltip: 'label.order'
              }
              case 'TOPUP': return {
                image: 'mdi-credit-card-plus',
                tooltip: 'label.topup'
              }
              case 'REWARD': return {
                image: 'mdi-gift',
                tooltip: 'label.reward'
              }
              case 'MEMBERSHIP_CANCELLATION': return {
                image: 'mdi-account-remove',
                tooltip: 'label.membershipCancellation'
              }
            }
          },
          sortable: true
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true,
          icon: transaction => transaction.amount > 0 ? { image: 'mdi-arrow-up-bold', color: 'success' } : { image: 'mdi-arrow-down-bold', color: 'error' }
        },
        {
          key: 'cardId',
          header: 'label.cardNumber',
          type: 'uuid',
          sortable: true
        },
      ],"items":_vm.transactionSearch,"search":true,"default-sort-property-key":"dateTime","item-key":"id","no-items-label":"message.noTransactions"}})
}
var staticRenderFns = []

export { render, staticRenderFns }