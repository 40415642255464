var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"card-edit","input-fields":[
        {
          key: 'tenantId',
          type: 'text',
          label: 'label.tenantId',
          readOnly: true
        },
        {
          key: 'id',
          type: 'uuid',
          label: 'label.cardNumber',
          readOnly: true
        },
        {
          key: 'name',
          type: 'text'
        },
        {
          key: 'disabled',
          type: 'switch',
          label: 'label.disabled',
          required: true,
          readOnly: !_vm.card.statusChangeable
        },
      ],"item":_vm.card,"save-handler":_vm.card.save,"uuid-item":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }