<template>
  <t-edit-dialog
    id="membership-request"
    :delete-handler="membershipRequest.delete"
    :input-fields="[
          {
            key: 'tenantName',
            type: 'text',
            label: 'label.club',
            readOnly: true
          }
        ]"
    :item="membershipRequest"
    :open-handler="onOpen"
    :action-chips="[
       {
          label: 'label.accept',
          icon: 'mdi-account-plus',
          handler: membershipRequest.accept
        }
    ]"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useMembershipRequest from '@/app/composables/membership/useMembershipRequest'

export default {
  data () {
    return {
      membershipRequest: useMembershipRequest()
    }
  },

  created () {
    useEvent().registerListener('membership-request', ['membership-request-accept', 'membership-request-delete'], () => this.$dialog.closeDialog('membership-request'))
  },

  destroyed () {
    useEvent().unregisterListener('membership-request')
  },

  methods: {
    onOpen () {
      this.membershipRequest.setData(this.$dialog.getDialogData('membership-request'))
    }
  }
}
</script>

<style scoped>

</style>
