<template>
  <t-table
    :item-properties="[
          {
            key: 'tenantName',
            header: 'label.club',
            sortable: true
          },
          {
            header: 'label.accept',
            icon: 'mdi-account-plus',
            iconClickHandler: membershipRequest => membershipRequest.accept()
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: membershipRequest => $dialog.openDialog('membership-request', membershipRequest)
          }
        ]"
    :items="membershipRequestList.membershipRequests"
    default-sort-property-key="tenantId"
    item-key="id"
    no-items-label="message.noDataFound"
    :search="true"
  />
</template>

<script>
export default {
  props: {
    membershipRequestList: {
      required: true
    }
  }
}
</script>
