<template>
  <t-page>
    <t-content-card
      :collapsible="true"
      :fetch="membershipRequestList.fetch"
      title="label.membershipRequests"
    >
      <membership-request-table :membership-request-list="membershipRequestList" />
    </t-content-card>

    <t-content-card
      :fetch="membershipList.loader"
      title="label.memberships"
    >
      <membership-table :membership-list="membershipList" :selection-handler="handleMembershipSelection"/>
    </t-content-card>

    <t-content-card
      v-if="transactionSearch"
      :fetch="transactionSearch.loader"
      title="label.transactions"
    >
      <transaction-table :transaction-search="transactionSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useMembershipList from '@/app/composables/membership/useMembershipList'
import useTransactionSearch from '@/app/composables/transaction/useTransactionSearch'
import useEvent from '@/lib/composables/useEvent'
import useMembershipRequestList from '@/app/composables/membership/useMembershipRequestList'

export default {
  data () {
    return {
      membershipRequestList: useMembershipRequestList(),
      membershipList: useMembershipList(),
      transactionSearch: null
    }
  },

  created () {
    this.membershipRequestList.load()
    this.membershipList.load()

    useEvent().registerListener(this, ['topup-done', 'membership-request-accept', 'membership-request-delete'], () => {
      this.membershipRequestList.loader.refresh()
      this.membershipList.loader.refresh()
      this.transactionSearch && this.transactionSearch.refresh()
    })
  },

  beforeDestroy () {
    useEvent().unregisterListener(this)
  },

  methods: {
    handleMembershipSelection (membership) {
      this.transactionSearch = useTransactionSearch(membership.tenantId)
    }
  }
}
</script>
