var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"order","input-fields":[
      {
        key: 'dateTime',
        type: 'date-time'
      },
      {
        key: 'tenantId',
        type: 'text'
      },
      {
        key: 'totalAmount',
        type: 'amount'
      },
      {
        key: 'items',
        label: 'label.products',
        type: 'list',
        itemKey: 'productId',
        itemText: 'description'
      }
    ],"item":_vm.order,"read-only-for-edit":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }