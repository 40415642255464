import $fetcher from '@/lib/fetcher'
import $authorization from '@/lib/authorization'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const membershipRequestTemplate = {
    id: null,
    tenantName: null
  }

  const membershipRequest = useEntity(membershipRequestTemplate)

  membershipRequest.accept = async () => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/membership-requests/${membershipRequest.id}/accept`)
    if (await fetch.promise) {
      useEvent().fire('membership-request-accept', membershipRequest)
    }
  }

  membershipRequest.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/membership-requests/${membershipRequest.id}`)
    if (await fetch.promise) {
      useEvent().fire('membership-request-delete', membershipRequest)
    }
  }

  return membershipRequest
}
