var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-page',[_c('t-content-card',{attrs:{"title":"label.topups","fetch":_vm.topupSearch.loader,"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: () => _vm.event.fire('topup'),
        disabledHandler: () => _vm.membershipList.topupEnabledMemberships.length === 0
      }
    ]}},[_c('topup-table',{attrs:{"topup-search":_vm.topupSearch}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }