var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"membership-request","delete-handler":_vm.membershipRequest.delete,"input-fields":[
        {
          key: 'tenantName',
          type: 'text',
          label: 'label.club',
          readOnly: true
        }
      ],"item":_vm.membershipRequest,"open-handler":_vm.onOpen,"action-chips":[
     {
        label: 'label.accept',
        icon: 'mdi-account-plus',
        handler: _vm.membershipRequest.accept
      }
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }