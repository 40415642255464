var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":_vm.membershipList.items,"item-key":"tenantId","selection-handler":_vm.selectionHandler,"enforce-selection":true,"item-properties":[
        {
          key: 'status',
          type: 'icon',
          icon: membership => {
            switch (membership.status) {
              case 'ENABLED': return {
                image: 'mdi-check-circle',
                color: 'primary',
                tooltip: 'label.enabled'
              }
              case 'DISABLED': return {
                image: 'mdi-account-off',
                color: 'error',
                tooltip: 'label.disabled'
              }
              case 'CANCELLED': return {
                image: 'mdi-account-remove',
                color: 'error',
                tooltip: 'label.cancelled'
              }
            }
          },
          sortable: true
        },
        {
          header: 'label.tenant',
          key: 'tenantName',
          sortable: true
        },
        {
          key: 'numberOfCards',
          sortable: true
        },
        {
          key: 'accountBalance',
          type: 'amount',
          sortable: true
        },
        {
          header: 'label.topup',
          iconClickHandler: membership => _vm.event.fire('topup', membership),
          icon: 'mdi-credit-card-plus',
          disabledHandler: membership => !membership.onlinePaymentAllowedForTopups
        }
      ],"default-sort-property-key":"tenantName","search":true,"no-items-label":"message.noAccounts"}})
}
var staticRenderFns = []

export { render, staticRenderFns }