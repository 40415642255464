var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":_vm.cardList.items,"item-key":"id","item-properties":[
        {
          key: 'tenantId',
          sortable: true
        },
        {
          key: 'id',
          header: 'label.cardNumber',
          type: 'uuid',
          sortable: true
        },
        {
          key: 'name',
          header: 'label.name',
          sortable: true
        },
        {
          key: 'disabled',
          type: 'icon',
          icon: card => card.disabled ? { image: 'mdi-credit-card-off', color: 'error' } : null,
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: card => _vm.event.fire('card-edit', card)
        }
      ],"default-sort-property-key":"tenantId","no-items-label":"message.noCards"}})
}
var staticRenderFns = []

export { render, staticRenderFns }